import React from "react";
import moment from "moment-timezone";
import Select from "react-select";
import { PatternFormat } from "react-number-format";
import { useFormik } from "formik";
import UIDatePicker from "./FormikUIDatePicker";
import * as yup from "yup";
import {
  countriesOptions,
  frequencyOfLoanReporting,
  statusOptions,
  entityTypes,
} from "../constants/CommonDropDownValues";
import { phoneRegExp, emailRegExp } from "../constants/CommonRegex";

const formikValuesToAPIPayloadMapper = (
  authenticityToken,
  action,
  {
    groupName,
    entityType,
    entityAddress,
    entityCountry,
    entityEmail,
    entityWebsite,
    reportingFrequency,
    sftpLogin,
    pgpPublicKey,
    groupStatus,
    statusNotes,
    credentialedAt,
    entityPhone,
    productionInquiries,
    webInquiries,
    productionTradelines,
    oldXMLFormat,
    semiMonthlyBilling,
    tradelineContributor,
    showLenderName,
    businessContact,
    businessEmail,
    businessPhone,
    operationsContact,
    operationsPhone,
    operationsEmail,
    complianceContact,
    compliancePhone,
    complianceEmail,
    reinvestigationContact,
    reinvestigationPhone,
    furnisherReinvestigationEmail,
    reinvestigationEmail,
    reinvestigationFax,
    reinvestigationAddress,
    consumerAddress,
    consumerContact,
    consumerEmail,
    consumerFax,
    consumerPhone,
  }
) => {
  let payload = {
    authenticity_token: authenticityToken,
    _method: "patch",
    "group[name]": groupName,
    "group[entity_type][]": "",
    "group[entity_address]": entityAddress,
    "group[entity_country]": entityCountry && entityCountry.value,
    "group[entity_phone]":
      entityPhone &&
        entityPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
    "group[entity_email]": entityEmail,
    "group[entity_website]": entityWebsite,
    "group[production_xml_inquiries]": +productionInquiries,
    "group[production_web_inquiries]": +webInquiries,
    "group[production_xml_tradelines]": +productionTradelines,
    "group[include_product_result]": +oldXMLFormat,
    "group[semi_monthly_billing]": +semiMonthlyBilling,
    "group[show_lender_name]": +showLenderName,
    "group[tradeline_contributor]": +tradelineContributor,
    "group[tradeline_reporting_schedule]":
      reportingFrequency && reportingFrequency.value,
    "group[sftp_login]": sftpLogin,
    "group[pgp_public_key]": pgpPublicKey && pgpPublicKey,
    "group[status]": groupStatus && groupStatus.value,
    "group[status_notes]": statusNotes,
    "group[credentialed_at]":
      credentialedAt && moment(credentialedAt).format("MM/DD/YYYY"),
    "group[business_contact]": businessContact,
    "group[business_phone]":
      businessPhone &&
      businessPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
    "group[business_email]": businessEmail,
    "group[operations_contact]": operationsContact,
    "group[operations_phone]":
      operationsPhone &&
      operationsPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
    "group[operations_email]": operationsEmail,
    "group[compliance_contact]": complianceContact,
    "group[compliance_phone]":
      compliancePhone &&
      compliancePhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
    "group[compliance_email]": complianceEmail,
    "group[reinvestigation_contact]": reinvestigationContact,
    "group[reinvestigation_phone]":
      reinvestigationPhone &&
      reinvestigationPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
    "group[inquiry_reinvestigation_email]": reinvestigationEmail,
    "group[furnisher_reinvestigation_email]": furnisherReinvestigationEmail,
    "group[reinvestigation_fax]":
      reinvestigationFax &&
      reinvestigationFax.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
    "group[reinvestigation_address]": reinvestigationAddress,
    "group[consumer_contact]": consumerContact,
    "group[consumer_phone]":
      consumerPhone &&
      consumerPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
    "group[consumer_email]": consumerEmail,
    "group[consumer_fax]":
      consumerFax && consumerFax.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
    "group[consumer_address]": consumerAddress,
    commit: action === "new" ? "Create" : "Update",
  };

  if (action == "new") {
    delete payload._method;
  }

  let formData = new FormData();

  Object.entries(payload).forEach(([k, v]) => formData.append(k, v || ""));

  formData.append("group[entity_type][]", "");
  if (entityType) {
    entityType.forEach(({ value }) => {
      formData.append("group[entity_type][]", value);
    });
  }

  return formData;
};
// yup.object().shape({ value: yup.string() }).notRequired()
const validationSchema = yup.object({
  groupName: yup.string().required("Please enter a value"),
  entityType: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
      })
    )
    .notRequired(),
  entityAddress: yup.string().notRequired(),
  entityCountry: yup
    .object()
    .shape({ value: yup.string().notRequired() })
    .notRequired(),
  entityPhone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid phone number in USA",
      excludeEmptyString: true,
    })
    .notRequired(),
  entityEmail: yup
    .string()
    .notRequired()
    .matches(emailRegExp, "Please enter a valid email address"),
  entityWebsite: yup.string().notRequired(),
  reportingFrequency: yup
    .object()
    .shape({ value: yup.string() })
    .required("Reporting Frequency is required"),
  sftpLogin: yup.string().notRequired(),
  pgpPublicKey: yup.string().notRequired(),
  groupStatus: yup
    .object()
    .shape({ value: yup.string().notRequired() })
    .notRequired(),
  statusNotes: yup.string().notRequired(),
  credentialedAt: yup
    .date()
    .typeError("Please enter a valid date")
    .notRequired(),
  productionInquiries: yup
    .bool()
    .notRequired(),
  webInquiries: yup.bool().notRequired(),
  productionTradelines: yup.bool().notRequired(),
  tradelineContributor: yup.bool().notRequired(),
  showLenderName: yup.bool().notRequired(),
  oldXMLFormat: yup.bool().notRequired(),
  semiMonthlyBilling: yup.bool().notRequired(),
  businessContact: yup.string().notRequired(),
  businessEmail: yup
    .string()
    .matches(emailRegExp, "Please enter a valid email address")
    .notRequired(),
  businessPhone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid phone number in USA",
      excludeEmptyString: true,
    })
    .notRequired(),
  operationsContact: yup.string().notRequired(),
  operationsPhone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid phone number in USA",
      excludeEmptyString: true,
    })
    .notRequired(),
  operationsEmail: yup
    .string()
    .matches(emailRegExp, "Please enter a valid email address")
    .notRequired(),
  complianceContact: yup.string().notRequired(),
  compliancePhone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid phone number in USA",
      excludeEmptyString: true,
    })
    .notRequired(),
  complianceEmail: yup
    .string()
    .matches(emailRegExp, "Please enter a valid email address")
    .notRequired(),
  reinvestigationContact: yup.string().notRequired(),
  reinvestigationPhone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid phone number in USA",
      excludeEmptyString: true,
    })
    .notRequired(),
  furnisherReinvestigationEmail: yup
    .string()
    .matches(emailRegExp, "Please enter a valid email address")
    .notRequired(),
  reinvestigationEmail: yup
    .string()
    .matches(emailRegExp, "Please enter a valid email address")
    .notRequired(),
  reinvestigationFax: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid fax number in USA",
      excludeEmptyString: true,
    })
    .notRequired(),
  reinvestigationAddress: yup.string().notRequired(),
  consumerContact: yup.string().notRequired(),
  consumerPhone: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid phone number in USA",
      excludeEmptyString: true,
    })
    .notRequired(),
  consumerEmail: yup
    .string()
    .matches(emailRegExp, "Please enter a valid email address")
    .notRequired(),
  consumerFax: yup
    .string()
    .matches(phoneRegExp, {
      message: "Please enter a valid fax number in USA",
      excludeEmptyString: true,
    })
    .notRequired(),
  consumerAddress: yup.string().notRequired(),
});

const initialValues = {
  groupName: "",
  entityType: "",
  entityAddress: "",
  entityCountry: "",
  entityEmail: "",
  entityWebsite: "",
  reportingFrequency: "",
  sftpLogin: "",
  pgpPublicKey: "",
  groupStatus: "",
  statusNotes: "",
  credentialedAt: "",
  entityPhone: "",
  productionInquiries: false,
  webInquiries: false,
  productionTradelines: false,
  oldXMLFormat: false,
  semiMonthlyBilling: false,
  tradelineContributor: false,
  showLenderName: false,
  businessContact: "",
  businessEmail: "",
  businessPhone: "",
  operationsContact: "",
  operationsPhone: "",
  operationsEmail: "",
  complianceContact: "",
  compliancePhone: "",
  complianceEmail: "",
  reinvestigationContact: "",
  reinvestigationPhone: "",
  furnisherReinvestigationEmail: "",
  reinvestigationEmail: "",
  reinvestigationFax: "",
  reinvestigationAddress: "",
  consumerAddress: "",
  consumerContact: "",
  consumerEmail: "",
  consumerFax: "",
  consumerPhone: "",
};

const GroupsEdit = (props) => {
  const { authenticityToken, userName, group, action } = props;
  const {
    id,
    name,
    entity_type,
    credentialed_at,
    status,
    status_notes,
    entity_phone,
    entity_email,
    entity_website,
    entity_address,
    entity_country,
    production_xml_inquiries: productionInquiries,
    production_web_inquiries: webInquiries,
    production_xml_tradelines: productionTradelines,
    include_product_result: oldXMLFormat,
    semi_monthly_billing,
    tradeline_reporting_schedule,
    tradeline_contributor,
    show_lender_name,
    sftp_login,
    pgp_public_key,
    business_contact,
    business_email,
    business_phone,
    operations_contact,
    operations_email,
    operations_phone,
    compliance_contact,
    compliance_email,
    compliance_phone,
    consumer_address,
    consumer_contact,
    consumer_email,
    consumer_fax,
    consumer_phone,
    reinvestigation_address,
    reinvestigation_contact,
    reinvestigation_fax,
    reinvestigation_phone,
    furnisher_reinvestigation_email,
    inquiry_reinvestigation_email,
  } = group;

  initialValues.groupName = name;

  if (typeof entity_type == "string") {
    initialValues.entityType = [{ value: entity_type, label: entity_type }];
  } else {
    initialValues.entityType =
      entity_type && entity_type.length == 0
        ? []
        : entity_type &&
          entity_type.map((type) => ({
            value: type,
            label: type,
          }));
  }
  initialValues.entityAddress = entity_address;
  initialValues.entityCountry = entity_country && {
    value: entity_country,
    label: entity_country,
  };
  initialValues.entityEmail = entity_email;
  initialValues.entityWebsite = entity_website;
  initialValues.reportingFrequency = tradeline_reporting_schedule && {
    value: tradeline_reporting_schedule,
    label: tradeline_reporting_schedule,
  };
  initialValues.sftpLogin = sftp_login;
  initialValues.pgpPublicKey = pgp_public_key;
  initialValues.groupStatus = status && {
    value: status,
    label: status,
  };
  initialValues.statusNotes = status_notes;
  initialValues.credentialedAt = credentialed_at && new Date(credentialed_at);
  initialValues.entityPhone =
    entity_phone && entity_phone.replace(/(\d{3})-(\d{3})-(\d{4})/, "$1$2$3");
  initialValues.semiMonthlyBilling = semi_monthly_billing;
  initialValues.oldXMLFormat = oldXMLFormat;
  initialValues.productionInquiries = !!productionInquiries;
  initialValues.webInquiries = webInquiries;
  initialValues.productionTradelines = productionTradelines;
  initialValues.tradelineContributor = tradeline_contributor;
  initialValues.showLenderName = show_lender_name;
  initialValues.businessContact = business_contact;
  initialValues.businessEmail = business_email;
  initialValues.businessPhone =
    business_phone &&
    business_phone.replace(/(\d{3})-(\d{3})-(\d{4})/, "$1$2$3");
  initialValues.operationsContact = operations_contact;
  initialValues.operationsEmail = operations_email;
  initialValues.operationsPhone =
    operations_phone &&
    operations_phone.replace(/(\d{3})-(\d{3})-(\d{4})/, "$1$2$3");
  initialValues.complianceContact = compliance_contact;
  initialValues.complianceEmail = compliance_email;
  initialValues.compliancePhone =
    compliance_phone &&
    compliance_phone.replace(/(\d{3})-(\d{3})-(\d{4})/, "$1$2$3");

  initialValues.reinvestigationContact = reinvestigation_contact;
  initialValues.reinvestigationPhone =
    reinvestigation_phone &&
    reinvestigation_phone.replace(/(\d{3})-(\d{3})-(\d{4})/, "$1$2$3");
  initialValues.furnisherReinvestigationEmail = furnisher_reinvestigation_email;
  initialValues.reinvestigationEmail = inquiry_reinvestigation_email;
  initialValues.reinvestigationFax =
    reinvestigation_fax &&
    reinvestigation_fax.replace(/(\d{3})-(\d{3})-(\d{4})/, "$1$2$3");
  initialValues.reinvestigationAddress = reinvestigation_address;
  initialValues.consumerContact = consumer_contact;
  initialValues.consumerPhone =
    consumer_phone &&
    consumer_phone.replace(/(\d{3})-(\d{3})-(\d{4})/, "$1$2$3");
  initialValues.consumerEmail = consumer_email;
  initialValues.consumerFax =
    consumer_fax && consumer_fax.replace(/(\d{3})-(\d{3})-(\d{4})/, "$1$2$3");
  initialValues.consumerAddress = consumer_address;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = formikValuesToAPIPayloadMapper(
        authenticityToken,
        action,
        values
      );

      fetch(action == "new" ? "/groups" : `/groups/${id}`, {
        method: "POST",
        body: payload,
      })
        .then((res) => res.json())
        .then((res) => {
          window.location.assign(`${window.location.origin}/groups`);
        });
    },
  });

  const copyAddressInfo = (from, to) => {
    formik.setFieldValue(`${to}Contact`, formik.values[`${from}Contact`]);
    formik.setFieldValue(`${to}Phone`, formik.values[`${from}Phone`]);
    formik.setFieldValue(`${to}Email`, formik.values[`${from}Email`]);
  };

  return (
    <form
      className="simple_form edit_group"
      id="new_inquiry"
      autoComplete="false"
      noValidate="novalidate"
      onSubmit={formik.handleSubmit}
      onBlur={formik.handleBlur}
    >
      <div className="row">
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Entity</h3>
            </div>
            <div className="panel-body">
              <div
                className={`form-group string required group_name has-${
                  formik.touched.groupName
                    ? formik.errors.groupName
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label string required"
                  htmlFor="group_name"
                >
                  <abbr title="required">*</abbr> Name
                </label>
                <input
                  className="form-control string required"
                  spellCheck="false"
                  required="required"
                  aria-required="true"
                  type="text"
                  value={formik.values.groupName}
                  onChange={formik.handleChange}
                  name="groupName"
                  id="group_name"
                />
                {formik.touched.groupName && formik.errors.groupName && (
                  <small className="help-block">
                    {formik.errors.groupName}
                  </small>
                )}
              </div>
              <div className="form-group select optional group_entity_type">
                <label
                  className="control-label select optional"
                  htmlFor="group_entity_type"
                >
                  Entity type
                </label>
                <Select
                  name="entityType"
                  id="group_entity_type"
                  className="optional selectize selectized group_entity_type"
                  spellCheck="false"
                  placeholder="Select a Entity Type"
                  multi
                  noOptionsMessage={() => null}
                  closeMenuOnSelect={false}
                  value={formik.values.entityType}
                  onChange={(value) => {
                    formik.setFieldValue("entityType", value);
                  }}
                  options={entityTypes}
                />
              </div>

              <div className="form-group text optional group_entity_address">
                <label
                  className="control-label text optional"
                  htmlFor="group_entity_address"
                >
                  Entity address
                </label>
                <textarea
                  className="form-control text optional"
                  spellCheck="false"
                  name="entityAddress"
                  id="group_entity_address"
                  onChange={formik.handleChange}
                >
                  {formik.values.entityAddress}
                </textarea>
              </div>
              <div className="form-group select optional group_entity_country">
                <label
                  className="control-label select optional"
                  htmlFor="group_entity_country"
                >
                  Entity country
                </label>
                <Select
                  name="entityCountry"
                  id="group_entity_country"
                  className="optional selectize selectized group_entity_country"
                  spellCheck="false"
                  placeholder="Select a Country"
                  value={formik.values.entityCountry}
                  onChange={(value) => {
                    formik.setFieldValue("entityCountry", value);
                  }}
                  options={countriesOptions}
                />
              </div>
              <div
                className={`form-group tel optional group_entity_phone has-${
                  formik.touched.entityPhone
                    ? formik.errors.entityPhone
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label tel optional"
                  htmlFor="group_entity_phone"
                >
                  Entity phone
                </label>
                <PatternFormat
                  className="form-control string tel optional phoneNumber"
                  spellCheck="false"
                  placeholder="(999) 000-9999"
                  format="(###) ###-####"
                  valueIsNumericString
                  mask="_"
                  id="group_entity_phone"
                  name="entityPhone"
                  value={formik.values.entityPhone}
                  onValueChange={(values) => {
                    const { _formattedValue, value } = values;
                    formik.setFieldValue("entityPhone", value);
                  }}
                />
                {formik.touched.entityPhone && formik.errors.entityPhone && (
                  <small className="help-block">
                    {formik.errors.entityPhone}
                  </small>
                )}
              </div>
              <div
                className={`form-group email optional group_entity_email has-${
                  formik.touched.entityEmail
                    ? formik.errors.entityEmail
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label email optional"
                  htmlFor="group_entity_email"
                >
                  Entity email
                </label>
                <input
                  className="form-control string email optional"
                  spellCheck="false"
                  type="text"
                  name="entityEmail"
                  id="group_entity_email"
                  onChange={formik.handleChange}
                  value={formik.values.entityEmail}
                />
                {formik.touched.entityEmail && formik.errors.entityEmail && (
                  <small className="help-block">
                    {formik.errors.entityEmail}
                  </small>
                )}
              </div>
              <div className="form-group string optional group_entity_website">
                <label
                  className="control-label string optional"
                  htmlFor="group_entity_website"
                >
                  Entity website
                </label>
                <input
                  className="form-control string optional"
                  spellCheck="false"
                  placeholder=""
                  type="text"
                  value={formik.values.entityWebsite}
                  onChange={formik.handleChange}
                  name="entityWebsite"
                  id="group_entity_website"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Integration</h3>
            </div>
            <div className="panel-body">
              <div className="form-group boolean optional group_production_xml_inquiries">
                <div className="checkbox">
                  <label
                    className="boolean optional"
                    htmlFor="group_production_xml_inquiries"
                  >
                    <input
                      className="boolean optional disabled"
                      type="checkbox"
                      name="productionInquiries"
                      id="group_production_xml_inquiries"
                      onChange={formik.handleChange}
                      checked={formik.values.productionInquiries}
                      value={formik.values.productionInquiries}
                    />
                    Allow Production Inquiries
                  </label>
                </div>
              </div>
              <div className="form-group boolean optional group_production_web_inquiries">
                <div className="checkbox">
                  <label
                    className="boolean optional"
                    htmlFor="group_production_web_inquiries"
                  >
                    <input
                      className="boolean optional disabled"
                      type="checkbox"
                      name="webInquiries"
                      id="group_production_web_inquiries"
                      onChange={formik.handleChange}
                      checked={formik.values.webInquiries}
                      value={formik.values.webInquiries}
                    />
                    Allow Production Web Inquiries
                  </label>
                </div>
              </div>
              <div className="form-group boolean optional group_production_xml_tradelines">
                <div className="checkbox">
                  <label
                    className="boolean optional"
                    htmlFor="group_production_xml_tradelines"
                  >
                    <input
                      className="boolean optional disabled"
                      type="checkbox"
                      name="productionTradelines"
                      id="group_production_xml_tradelines"
                      onChange={formik.handleChange}
                      checked={formik.values.productionTradelines}
                      value={formik.values.productionTradelines}
                    />
                    Allow Production Tradelines
                  </label>
                </div>
              </div>
              <div className="form-group boolean optional disabled group_include_product_result">
                <div className="checkbox">
                  <label
                    className="boolean optional disabled"
                    htmlFor="group_include_product_result"
                  >
                    <input
                      className="boolean optional disabled"
                      disabled="disabled"
                      type="checkbox"
                      name="oldXMLFormat"
                      id="group_include_product_result"
                      onChange={formik.handleChange}
                      checked={formik.values.oldXMLFormat}
                      value={formik.values.oldXMLFormat}
                    />
                    Old XML Format
                  </label>
                </div>
              </div>
              <div className="form-group boolean optional group_semi_monthly_billing">
                <div className="checkbox">
                  <label
                    className="boolean optional"
                    htmlFor="group_semi_monthly_billing"
                  >
                    <input
                      autoComplete="off"
                      className="boolean optional"
                      type="checkbox"
                      name="semiMonthlyBilling"
                      id="group_semi_monthly_billing"
                      onChange={formik.handleChange}
                      checked={formik.values.semiMonthlyBilling}
                      value={formik.values.semiMonthlyBilling}
                    />
                    Semi Monthly Billing
                  </label>
                </div>
              </div>
              <div className="form-group boolean optional group_show_lender_name">
                <div className="checkbox">
                  <label
                    className="boolean optional"
                    htmlFor="group_show_lender_name"
                  >
                    <input
                      autoComplete="off"
                      className="boolean optional"
                      type="checkbox"
                      name="showLenderName"
                      id="group_show_lender_name"
                      onChange={formik.handleChange}
                      checked={formik.values.showLenderName}
                      value={formik.values.showLenderName}
                    />
                    Show Lender Name
                  </label>
                </div>
              </div>
              <div className="form-group boolean optional group_tradeline_contributor">
                <div className="checkbox">
                  <label
                    className="boolean optional"
                    htmlFor="group_tradeline_contributor"
                  >
                    <input
                      autoComplete="off"
                      className="boolean optional"
                      type="checkbox"
                      name="tradelineContributor"
                      id="group_tradeline_contributor"
                      onChange={formik.handleChange}
                      checked={formik.values.tradelineContributor}
                      value={formik.values.tradelineContributor}
                    />
                    Tradeline Contributor
                  </label>
                </div>
              </div>
              <div
                className={`form-group select required group_tradeline_reporting_schedule has-${
                  formik.touched.reportingFrequency
                    ? formik.errors.reportingFrequency
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label select required"
                  htmlFor="group_tradeline_reporting_schedule"
                >
                  <abbr title="required">*</abbr> Reporting Frequency
                </label>
                <Select
                  name="reportingFrequency"
                  id="group_tradeline_reporting_schedule"
                  className="optional selectize selectized group_tradeline_reporting_schedule"
                  spellCheck="false"
                  placeholder="Frequency of Loan Reporting"
                  value={formik.values.reportingFrequency}
                  onChange={(value) => {
                    formik.setFieldValue("reportingFrequency", value);
                  }}
                  options={frequencyOfLoanReporting}
                />
                {formik.touched.reportingFrequency &&
                  formik.errors.reportingFrequency && (
                    <small className="help-block">
                      {formik.errors.reportingFrequency}
                    </small>
                  )}
              </div>
              <div className="form-group string optional group_sftp_login">
                <label
                  className="control-label string optional"
                  htmlFor="group_sftp_login"
                >
                  SFTP login
                </label>
                <input
                  className="form-control string optional"
                  spellCheck="false"
                  type="text"
                  value={formik.values.sftpLogin}
                  onChange={formik.handleChange}
                  name="sftpLogin"
                  id="group_sftp_login"
                />
              </div>
              <div className="form-group text optional group_pgp_public_key">
                <label
                  className="control-label text optional"
                  htmlFor="group_pgp_public_key"
                >
                  PGP Public Key
                </label>
                <textarea
                  className="form-control text optional"
                  spellCheck="false"
                  name="pgpPublicKey"
                  id="group_pgp_public_key"
                  onChange={formik.handleChange}
                >
                  {formik.values.pgpPublicKey}
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Notes</h3>
            </div>
            <div className="panel-body">
              <div className="form-group select optional group_status">
                <label
                  className="control-label select optional"
                  htmlFor="group_status"
                >
                  Status
                </label>
                <Select
                  name="groupStatus"
                  id="group_status"
                  className="optional selectize selectized group_status"
                  spellCheck="false"
                  placeholder="Select a Status"
                  value={formik.values.groupStatus}
                  onChange={(value) => {
                    formik.setFieldValue("groupStatus", value);
                  }}
                  options={statusOptions}
                />
              </div>
              <div className="form-group text optional group_status_notes">
                <label
                  className="control-label text optional"
                  htmlFor="group_status_notes"
                >
                  Status notes
                </label>
                <textarea
                  className="form-control text optional"
                  spellCheck="false"
                  maxLength="255"
                  rows="3"
                  name="statusNotes"
                  id="group_status_notes"
                  onChange={formik.handleChange}
                >
                  {formik.values.statusNotes}
                </textarea>
              </div>
              <div
                className={`form-group optional group_credentialed_at has-${
                  formik.touched.credentialedAt
                    ? formik.errors.credentialedAt
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label optional"
                  htmlFor="group_credentialed_at"
                >
                  Credentialed at
                </label>
                <UIDatePicker
                  showTodayButton
                  className="date_picker optional"
                  label=""
                  placeholder="MM/DD/YYYY"
                  type="text"
                  name="credentialedAt"
                  id="group_credentialed_at"
                  date={formik.values.credentialedAt}
                  onChangeDate={(value) => {
                    formik.setFieldValue("credentialedAt", value);
                  }}
                  showErrorMessage={false}
                />
                {formik.touched.credentialedAt &&
                  formik.errors.credentialedAt && (
                    <small className="help-block">
                      {formik.errors.credentialedAt}
                    </small>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Business Contact</h3>
            </div>
            <div className="panel-body">
              <div className="form-group string optional group_business_contact">
                <label
                  className="control-label string optional"
                  htmlFor="group_business_contact"
                >
                  Business contact
                </label>
                <input
                  className="form-control string optional"
                  spellCheck="false"
                  type="text"
                  name="businessContact"
                  id="group_business_contact"
                  value={formik.values.businessContact}
                  onChange={formik.handleChange}
                />
              </div>
              <div
                className={`form-group tel optional group_business_phone has-${
                  formik.touched.businessPhone
                    ? formik.errors.businessPhone
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label tel optional"
                  htmlFor="group_business_phone"
                >
                  Business phone
                </label>
                <PatternFormat
                  className="form-control string tel optional phoneNumber"
                  spellCheck="false"
                  placeholder="(999) 000-9999"
                  format="(###) ###-####"
                  valueIsNumericString
                  mask="_"
                  id="group_business_phone"
                  name="businessPhone"
                  value={formik.values.businessPhone}
                  onValueChange={(values) => {
                    const { _formattedValue, value } = values;
                    formik.setFieldValue("businessPhone", value);
                  }}
                />
                {formik.touched.businessPhone &&
                  formik.errors.businessPhone && (
                    <small className="help-block">
                      {formik.errors.businessPhone}
                    </small>
                  )}
              </div>
              <div
                className={`form-group email optional group_business_email has-${
                  formik.touched.businessEmail
                    ? formik.errors.businessEmail
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label email optional"
                  htmlFor="group_business_email"
                >
                  Business email
                </label>
                <input
                  className="form-control string email optional"
                  spellCheck="false"
                  type="email"
                  name="businessEmail"
                  id="group_business_email"
                  value={formik.values.businessEmail}
                  onChange={formik.handleChange}
                />
                {formik.touched.businessEmail &&
                  formik.errors.businessEmail && (
                    <small className="help-block">
                      {formik.errors.businessEmail}
                    </small>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">
                Operations Contact
                <div
                  className="btn btn-xs btn-default btn-primary pull-right"
                  id="copy_operations"
                  onClick={() => copyAddressInfo("business", "operations")}
                >
                  Copy from Business
                </div>
              </h3>
            </div>
            <div className="panel-body">
              <div className="form-group string optional group_operations_contact">
                <label
                  className="control-label string optional"
                  htmlFor="group_operations_contact"
                >
                  Operations contact
                </label>
                <input
                  className="form-control string optional"
                  spellCheck="false"
                  type="text"
                  value={formik.values.operationsContact}
                  name="operationsContact"
                  id="group_operations_contact"
                  onChange={formik.handleChange}
                />
              </div>
              <div
                className={`form-group tel optional group_operations_phone has-${
                  formik.touched.operationsPhone
                    ? formik.errors.operationsPhone
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label tel optional"
                  htmlFor="group_operations_phone"
                >
                  Operations phone
                </label>
                <PatternFormat
                  className="form-control string tel optional phoneNumber"
                  spellCheck="false"
                  placeholder="(999) 000-9999"
                  format="(###) ###-####"
                  valueIsNumericString
                  mask="_"
                  id="group_operations_phone"
                  name="operationsPhone"
                  value={formik.values.operationsPhone}
                  onValueChange={(values) => {
                    const { _formattedValue, value } = values;
                    formik.setFieldValue("operationsPhone", value);
                  }}
                />
                {formik.touched.operationsPhone &&
                  formik.errors.operationsPhone && (
                    <small className="help-block">
                      {formik.errors.operationsPhone}
                    </small>
                  )}
              </div>
              <div
                className={`form-group email optional group_operations_email has-${
                  formik.touched.operationsEmail
                    ? formik.errors.operationsEmail
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label email optional"
                  htmlFor="group_operations_email"
                >
                  Operations email
                </label>
                <input
                  className="form-control string email optional"
                  spellCheck="false"
                  type="email"
                  value={formik.values.operationsEmail}
                  name="operationsEmail"
                  id="group_operations_email"
                  onChange={formik.handleChange}
                />
                {formik.touched.operationsEmail &&
                  formik.errors.operationsEmail && (
                    <small className="help-block">
                      {formik.errors.operationsEmail}
                    </small>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">
                Compliance Contact
                <div
                  className="btn btn-xs btn-default btn-primary pull-right"
                  id="copy_compliance"
                  onClick={() => copyAddressInfo("business", "compliance")}
                >
                  Copy from Business
                </div>
              </h3>
            </div>
            <div className="panel-body">
              <div className="form-group string optional group_compliance_contact">
                <label
                  className="control-label string optional"
                  htmlFor="group_compliance_contact"
                >
                  Compliance contact
                </label>
                <input
                  className="form-control string optional"
                  spellCheck="false"
                  type="text"
                  value={formik.values.complianceContact}
                  name="complianceContact"
                  id="group_compliance_contact"
                  onChange={formik.handleChange}
                />
              </div>
              <div
                className={`form-group tel optional group_compliance_phone has-${
                  formik.touched.compliancePhone
                    ? formik.errors.compliancePhone
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label tel optional"
                  htmlFor="group_compliance_phone"
                >
                  Compliance phone
                </label>
                <PatternFormat
                  className="form-control string tel optional phoneNumber"
                  spellCheck="false"
                  placeholder="(999) 000-9999"
                  format="(###) ###-####"
                  valueIsNumericString
                  mask="_"
                  id="group_compliance_phone"
                  name="compliancePhone"
                  value={formik.values.compliancePhone}
                  onValueChange={(values) => {
                    const { _formattedValue, value } = values;
                    formik.setFieldValue("compliancePhone", value);
                  }}
                />
                {formik.touched.compliancePhone &&
                  formik.errors.compliancePhone && (
                    <small className="help-block">
                      {formik.errors.compliancePhone}
                    </small>
                  )}
              </div>
              <div
                className={`form-group email optional group_compliance_email has-${
                  formik.touched.complianceEmail
                    ? formik.errors.complianceEmail
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label email optional"
                  htmlFor="group_compliance_email"
                >
                  Compliance email
                </label>
                <input
                  className="form-control string email optional"
                  spellCheck="false"
                  type="email"
                  value={formik.values.complianceEmail}
                  name="complianceEmail"
                  id="group_compliance_email"
                  onChange={formik.handleChange}
                />
                {formik.touched.complianceEmail &&
                  formik.errors.complianceEmail && (
                    <small className="help-block">
                      {formik.errors.complianceEmail}
                    </small>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">
                Reinvestigation Contact
                <div
                  className="btn btn-xs btn-default btn-primary pull-right"
                  id="copy_reinvestigation"
                  onClick={() => copyAddressInfo("business", "reinvestigation")}
                >
                  Copy from Business
                </div>
              </h3>
            </div>
            <div className="panel-body">
              <div className="form-group string optional group_reinvestigation_contact">
                <label
                  className="control-label string optional"
                  htmlFor="group_reinvestigation_contact"
                >
                  Reinvestigation contact
                </label>
                <input
                  className="form-control string optional"
                  spellCheck="false"
                  type="text"
                  value={formik.values.reinvestigationContact}
                  name="reinvestigationContact"
                  id="group_reinvestigation_contact"
                  onChange={formik.handleChange}
                />
              </div>
              <div
                className={`form-group tel optional group_reinvestigation_phone has-${
                  formik.touched.reinvestigationPhone
                    ? formik.errors.reinvestigationPhone
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label tel optional"
                  htmlFor="group_reinvestigation_phone"
                >
                  Reinvestigation phone
                </label>
                <PatternFormat
                  className="form-control string tel optional phoneNumber"
                  spellCheck="false"
                  placeholder="(999) 000-9999"
                  format="(###) ###-####"
                  valueIsNumericString
                  mask="_"
                  id="group_reinvestigation_phone"
                  name="reinvestigationPhone"
                  value={formik.values.reinvestigationPhone}
                  onValueChange={(values) => {
                    const { _formattedValue, value } = values;
                    formik.setFieldValue("reinvestigationPhone", value);
                  }}
                />
                {formik.touched.reinvestigationPhone &&
                  formik.errors.reinvestigationPhone && (
                    <small className="help-block">
                      {formik.errors.reinvestigationPhone}
                    </small>
                  )}
              </div>
              <div
                className={`form-group email optional group_inquiry_reinvestigation_email has-${
                  formik.touched.reinvestigationEmail
                    ? formik.errors.reinvestigationEmail
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label email optional"
                  htmlFor="group_inquiry_reinvestigation_email"
                >
                  Inquiry reinvestigation email
                </label>
                <input
                  className="form-control string email optional"
                  spellCheck="false"
                  type="text"
                  name="reinvestigationEmail"
                  id="group_reinvestigation_email"
                  onChange={formik.handleChange}
                  value={formik.values.reinvestigationEmail}
                />
                {formik.touched.reinvestigationEmail &&
                  formik.errors.reinvestigationEmail && (
                    <small className="help-block">
                      {formik.errors.reinvestigationEmail}
                    </small>
                  )}
              </div>
              <div
                className={`form-group email optional group_furnisher_reinvestigation_email has-${
                  formik.touched.furnisherReinvestigationEmail
                    ? formik.errors.furnisherReinvestigationEmail
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label email optional"
                  htmlFor="group_furnisher_reinvestigation_email"
                >
                  Furnisher reinvestigation email
                </label>
                <input
                  className="form-control string email optional"
                  spellCheck="false"
                  type="text"
                  name="furnisherReinvestigationEmail"
                  id="group_reinvestigation_email"
                  onChange={formik.handleChange}
                  value={formik.values.furnisherReinvestigationEmail}
                />
                {formik.touched.furnisherReinvestigationEmail &&
                  formik.errors.furnisherReinvestigationEmail && (
                    <small className="help-block">
                      {formik.errors.furnisherReinvestigationEmail}
                    </small>
                  )}
              </div>
              <div
                className={`form-group string optional group_reinvestigation_fax has-${
                  formik.touched.reinvestigationFax
                    ? formik.errors.reinvestigationFax
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label string optional"
                  htmlFor="group_reinvestigation_fax"
                >
                  Reinvestigation fax
                </label>
                <PatternFormat
                  className="form-control string tel optional phoneNumber"
                  spellCheck="false"
                  placeholder="(999) 000-9999"
                  format="(###) ###-####"
                  valueIsNumericString
                  mask="_"
                  id="group_reinvestigation_fax"
                  name="reinvestigationFax"
                  value={formik.values.reinvestigationFax}
                  onValueChange={(values) => {
                    const { _formattedValue, value } = values;
                    formik.setFieldValue("reinvestigationFax", value);
                  }}
                />
                {formik.touched.reinvestigationFax &&
                  formik.errors.reinvestigationFax && (
                    <small className="help-block">
                      {formik.errors.reinvestigationFax}
                    </small>
                  )}
              </div>
              <div className="form-group text optional group_reinvestigation_address">
                <label
                  className="control-label text optional"
                  htmlFor="group_reinvestigation_address"
                >
                  Reinvestigation address
                </label>
                <textarea
                  className="form-control text optional"
                  spellCheck="false"
                  name="reinvestigationAddress"
                  id="group_reinvestigation_address"
                  onChange={formik.handleChange}
                >
                  {formik.values.reinvestigationAddress}
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">
                Consumer Contact
                <div
                  className="btn btn-xs btn-default btn-primary pull-right"
                  id="copy_consumer"
                  onClick={() => copyAddressInfo("business", "consumer")}
                >
                  Copy from Business
                </div>
              </h3>
            </div>
            <div className="panel-body">
              <div className="form-group string optional group_consumer_contact">
                <label
                  className="control-label string optional"
                  htmlFor="group_consumer_contact"
                >
                  Consumer contact
                </label>
                <input
                  className="form-control string optional"
                  spellCheck="false"
                  type="text"
                  value={formik.values.consumerContact}
                  name="consumerContact"
                  id="group_consumer_contact"
                  onChange={formik.handleChange}
                />
              </div>
              <div
                className={`form-group tel optional group_consumer_phone has-${
                  formik.touched.consumerPhone
                    ? formik.errors.consumerPhone
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label tel optional"
                  htmlFor="group_consumer_phone"
                >
                  Consumer phone
                </label>
                <PatternFormat
                  className="form-control string tel optional phoneNumber"
                  spellCheck="false"
                  placeholder="(999) 000-9999"
                  format="(###) ###-####"
                  valueIsNumericString
                  mask="_"
                  id="group_consumer_phone"
                  name="consumerPhone"
                  value={formik.values.consumerPhone}
                  onValueChange={(values) => {
                    const { _formattedValue, value } = values;
                    formik.setFieldValue("consumerPhone", value);
                  }}
                />
                {formik.touched.consumerPhone &&
                  formik.errors.consumerPhone && (
                    <small className="help-block">
                      {formik.errors.consumerPhone}
                    </small>
                  )}
              </div>
              <div
                className={`form-group email optional group_consumer_email has-${
                  formik.touched.consumerEmail
                    ? formik.errors.consumerEmail
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label email optional"
                  htmlFor="group_consumer_email"
                >
                  Consumer email
                </label>
                <input
                  className="form-control string email optional"
                  spellCheck="false"
                  type="text"
                  name="consumerEmail"
                  id="group_consumer_email"
                  onChange={formik.handleChange}
                  value={formik.values.consumerEmail}
                />
                {formik.touched.consumerEmail &&
                  formik.errors.consumerEmail && (
                    <small className="help-block">
                      {formik.errors.consumerEmail}
                    </small>
                  )}
              </div>
              <div
                className={`form-group string optional group_consumer_fax has-${
                  formik.touched.consumerFax
                    ? formik.errors.consumerFax
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="control-label string optional"
                  htmlFor="group_consumer_fax"
                >
                  Consumer fax
                </label>
                <PatternFormat
                  className="form-control string tel optional phoneNumber"
                  spellCheck="false"
                  placeholder="(999) 000-9999"
                  format="(###) ###-####"
                  valueIsNumericString
                  mask="_"
                  id="group_consumer_fax"
                  name="consumerFax"
                  value={formik.values.consumerFax}
                  onValueChange={(values) => {
                    const { _formattedValue, value } = values;
                    formik.setFieldValue("consumerFax", value);
                  }}
                />
                {formik.touched.consumerFax && formik.errors.consumerFax && (
                  <small className="help-block">
                    {formik.errors.consumerFax}
                  </small>
                )}
              </div>
              <div className="form-group text optional group_consumer_address">
                <label
                  className="control-label text optional"
                  htmlFor="group_consumer_address"
                >
                  Consumer address
                </label>
                <textarea
                  className="form-control text optional"
                  spellCheck="false"
                  name="consumerAddress"
                  id="group_consumer_address"
                  onChange={formik.handleChange}
                >
                  {formik.values.consumerAddress}
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-actions">
            <input
              type="submit"
              name="commit"
              value={action === "new" ? "Create" : "Update"}
              className="btn btn-primary"
              data-disable-with="Update"
            />
            <a className="btn btn-default" href="/groups">
              Cancel
            </a>
          </div>
        </div>
      </div>
    </form>
  );
};

GroupsEdit.propTypes = {};

export default GroupsEdit;
