import React from "react";
import moment from "moment-timezone";

const GroupsShow = (props) => {
  const { authenticityToken, userName, group } = props;
  const {
    id,
    entity_type,
    credentialed_at,
    status,
    status_notes,
    entity_phone,
    entity_email,
    entity_website,
    entity_address,
    entity_country,
    updated_at,
    production_xml_inquiries: productionInquiries,
    production_web_inquiries: webInquiries,
    production_xml_tradelines: productionTradelines,
    include_product_result: oldXMLFormat,
    semi_monthly_billing,
    tradeline_reporting_schedule,
    tradeline_contributor,
    show_lender_name,
    accounts,
    sftp_login,
    business_contact,
    business_email,
    business_phone,
    operations_contact,
    operations_email,
    operations_phone,
    compliance_contact,
    compliance_email,
    compliance_phone,
    consumer_address,
    consumer_contact,
    consumer_email,
    consumer_fax,
    consumer_phone,
    reinvestigation_address,
    reinvestigation_contact,
    reinvestigation_fax,
    reinvestigation_phone,
    furnisher_reinvestigation_email,
    inquiry_reinvestigation_email,
  } = group;

  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Entity</h3>
            </div>
            <div className="panel-body">
              <dl>
                <dt>ID</dt>
                <dd>{id}</dd>
                <dt>Entity Type</dt>
                <dd>{entity_type}</dd>
                <dt>Phone</dt>
                <dd>{entity_phone}</dd>
                <dt>Email</dt>
                <dd>{entity_email}</dd>
                <dt>Website</dt>
                <dd>{entity_website}</dd>
                <dt>Address</dt>
                <dd>
                  {entity_address.split("\n").map((addr, id) => (
                    <div key={`addr-${id}`}>{addr}</div>
                  ))}
                </dd>
                <dt>Country</dt>
                <dd>{entity_country}</dd>
                <dt>Updated At</dt>
                <dd>
                  {moment(updated_at)
                    .tz("EST")
                    .format("MM/DD/YYYY H:mm:ss a z")}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Integration</h3>
            </div>
            <div className="panel-body">
              <ul className="list-unstyled icons">
                <li>
                  <i
                    className={`fa ${
                      productionInquiries ? "fa-check-square-o" : "fa-square-o"
                    }`}
                  ></i>
                  Allow Production Inquiries
                </li>
                <li>
                  <i
                    className={`fa ${
                      webInquiries ? "fa-check-square-o" : "fa-square-o"
                    }`}
                  ></i>
                  Allow Production Web Inquiries
                </li>
                <li>
                  <i
                    className={`fa ${
                      oldXMLFormat ? "fa-check-square-o" : "fa-square-o"
                    }`}
                  ></i>
                  Old XML Format
                </li>
                <li>
                  <i
                    className={`fa ${
                      semi_monthly_billing ? "fa-check-square-o" : "fa-square-o"
                    }`}
                  ></i>
                  Semi Monthly Billing
                </li>
                <li>
                  <i
                    className={`fa ${
                      show_lender_name ? "fa-check-square-o" : "fa-square-o"
                    }`}
                  ></i>
                  Show Lender Name
                </li>
                <li>
                  <i
                    className={`fa ${
                      tradeline_contributor
                        ? "fa-check-square-o"
                        : "fa-square-o"
                    }`}
                  ></i>
                  Tradeline Contributor
                </li>
                <li>
                  <i
                    className={`fa ${
                      productionTradelines ? "fa-check-square-o" : "fa-square-o"
                    }`}
                  ></i>
                  Allow Production Tradelines
                </li>
              </ul>
              <dl>
                <dt>Tradeline Reporting Frequency</dt>
                <dd>{tradeline_reporting_schedule}</dd>
                <dt>SFTP Login</dt>
                <dd>{sftp_login}</dd>
                <dt>PGP Public Key</dt>
                <dd></dd>
              </dl>
            </div>
          </div>
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Clarity Assignments</h3>
            </div>
            <div className="panel-body">
              <dl>
                <dt>Account Manager</dt>
                <dd></dd>
                <dt>Account Executive</dt>
                <dd></dd>
                <dt>
                  <span
                    className="translation_missing"
                    title="translation missing: en.relationship_manager"
                  >
                    Relationship Manager
                  </span>
                </dt>
                <dd></dd>
                <dt>Service Entities</dt>
                <dd></dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Notes</h3>
            </div>
            <div className="panel-body">
              <dl>
                <dt>Status</dt>
                <dd>{status}</dd>
                <dt>Status Notes</dt>
                <dd>{status_notes}</dd>
                <dt>Credentialed At</dt>
                <dd>{credentialed_at && moment(credentialed_at).format("M/D/YYYY")}</dd>
              </dl>
            </div>
          </div>
          <div className="panel panel-default bg-fa">
            <div className="panel-body">
              <a className="bold" href={`/groups/${id}/accounts`}>
                {accounts.length} Account(s)
              </a>
              <a className="pull-right" href={`/groups/${id}/accounts/new`}>
                <i className="fa fa-plus-circle"></i> Create Account
              </a>
              <div className="clearfix"></div>
              <ul className="sub-list">
                {accounts.map(({ id: gid, name }) => (
                  <li className="link" key={gid}>
                    <div className="btn-group">
                      <div
                        className="btn btn-xs btn-default dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <span className="caret"></span>
                      </div>
                      <ul className="dropdown-menu">
                        <li>
                          <a href={`/groups/${id}/accounts/${gid}`}>
                            <i className="fa fa-show"></i> Show
                          </a>
                        </li>
                        <li>
                          <a href={`/groups/${id}/accounts/${gid}/edit`}>
                            <i className="fa fa-edit"></i> Edit
                          </a>
                        </li>
                        <li>
                          <a href={`/accounts/${gid}/revisions`}>
                            <i className="fa fa-revisions"></i> Revisions
                          </a>
                        </li>
                      </ul>
                    </div>
                    ({gid}) {name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Business Contact</h3>
            </div>
            <div className="panel-body">
              <dl>
                <dt>Contact</dt>
                <dd>{business_contact}</dd>
                <dt>Phone</dt>
                <dd>{business_phone}</dd>
                <dt>Email</dt>
                <dd>{business_email}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Operations Contact</h3>
            </div>
            <div className="panel-body">
              <dl>
                <dt>Contact</dt>
                <dd>{operations_contact}</dd>
                <dt>Phone</dt>
                <dd>{operations_phone}</dd>
                <dt>Email</dt>
                <dd>{operations_email}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Compliance Contact</h3>
            </div>
            <div className="panel-body">
              <dl>
                <dt>Contact</dt>
                <dd>{compliance_contact}</dd>
                <dt>Phone</dt>
                <dd>{compliance_phone}</dd>
                <dt>Email</dt>
                <dd>{compliance_email}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Reinvestigation Contact</h3>
            </div>
            <div className="panel-body">
              <dl>
                <dt>Contact</dt>
                <dd>{reinvestigation_contact}</dd>
                <dt>Phone</dt>
                <dd>{reinvestigation_phone}</dd>
                <dt>Fax</dt>
                <dd>{reinvestigation_fax}</dd>
                <dt>
                  <span
                    className="translation_missing"
                    title="translation missing: en.inquiry email"
                  >
                    Inquiry Email
                  </span>
                </dt>
                <dd>{inquiry_reinvestigation_email}</dd>
                <dt>
                  <span
                    className="translation_missing"
                    title="translation missing: en.furnisher email"
                  >
                    Furnisher Email
                  </span>
                </dt>
                <dd>{furnisher_reinvestigation_email}</dd>
                <dt>Address</dt>
                <dd>
                  {reinvestigation_address.split("\n").map((addr, id) => (
                    <div key={`reinvestigation-${id}`}>{addr}</div>
                  ))}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Consumer Contact</h3>
            </div>
            <div className="panel-body">
              <dl>
                <dt>Contact</dt>
                <dd>{consumer_contact}</dd>
                <dt>Phone</dt>
                <dd>{consumer_phone}</dd>
                <dt>Fax</dt>
                <dd>{consumer_fax}</dd>
                <dt>Email</dt>
                <dd>{consumer_email}</dd>
                <dt>Address</dt>
                <dd>
                  {consumer_address.split("\n").map((addr, id) => (
                    <div key={`consumer-${id}`}>{addr}</div>
                  ))}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GroupsShow.propTypes = {};

export default GroupsShow;
