import React from "react";

const Groups = (props) => {
  const {
    clarityUser,
    groups,
    isAdmin,
    statusSetter,
  } = props;

  return (
    <>
      {groups.map(
        ({
          id,
          name,
          entity_type,
          status,
          status_notes,
          production_xml_inquiries: productionInquiries,
          production_web_inquiries: webInquiries,
          production_xml_tradelines: productionTradelines,
          include_product_result: oldXMLFormat,
          semi_monthly_billing,
          tradeline_contributor,
          show_lender_name,
          accounts,
        }) => (
          <div key={id} className="row">
            <div className="active col-lg-12 row-block" id={id}>
              <div className="header">
                <div className="row">
                  <div className="col-sm-3">{name}</div>
                  {clarityUser && (
                    <div
                      className="status"
                      style={{ textTransform: "uppercase" }}
                    >
                      {status}
                    </div>
                  )}
                </div>
              </div>
              <div className="body">
                <div className="row">
                  <div className="col-sm-3">
                    <dl>
                      <dt>ID</dt>
                      <dd>{id}</dd>
                      <dt>Entity Type</dt>
                      <dd>{entity_type}</dd>
                      {clarityUser && (
                        <>
                          <dt>Account Manager</dt>
                          <dd></dd>
                          <dt>Account Executive</dt>
                          <dd></dd>
                          <dt>
                            <span
                              className="translation_missing"
                              title="translation missing: en.relationship_manager"
                            >
                              Relationship Manager
                            </span>
                          </dt>
                          <dd></dd>
                          <dt>Service Entities</dt>
                          <dd></dd>
                        </>
                      )}
                    </dl>
                    <br />
                  </div>
                  {clarityUser && (
                    <div className="col-sm-3">
                      <ul className="icons">
                        <li>
                          <i
                            className={`fa ${
                              productionInquiries
                                ? "fa-check-square-o"
                                : "fa-square-o"
                            }`}
                          ></i>
                          Allow Production Inquiries
                        </li>
                        <li>
                          <i
                            className={`fa ${
                              webInquiries ? "fa-check-square-o" : "fa-square-o"
                            }`}
                          ></i>
                          Allow Web Inquiries
                        </li>
                        <li>
                          <i
                            className={`fa ${
                              productionTradelines
                                ? "fa-check-square-o"
                                : "fa-square-o"
                            }`}
                          ></i>
                          Allow Production Tradelines
                        </li>
                        <li>
                          <i
                            className={`fa ${
                              oldXMLFormat ? "fa-check-square-o" : "fa-square-o"
                            }`}
                          ></i>
                          Old XML Format
                        </li>
                        <li>
                          <i
                            className={`fa ${
                              semi_monthly_billing
                                ? "fa-check-square-o"
                                : "fa-square-o"
                            }`}
                          ></i>
                          Semi Monthly Billing
                        </li>
                        <li>
                          <i
                            className={`fa ${
                              tradeline_contributor
                                ? "fa-check-square-o"
                                : "fa-square-o"
                            }`}
                          ></i>
                          Tradeline Contributor
                        </li>
                        <li>
                          <i
                            className={`fa ${
                              show_lender_name
                                ? "fa-check-square-o"
                                : "fa-square-o"
                            }`}
                          ></i>
                          Show Lender Name
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="col-sm-3"></div>
                  <div className="col-sm-3">
                    <a className="bold" href={`/groups/${id}/accounts`}>
                      {accounts.length} Account(s)
                    </a>
                    {clarityUser && (
                      <a
                        className="pull-right"
                        href={`/groups/${id}/accounts/new`}
                      >
                        <i className="fa fa-plus-circle"></i> Create Account
                      </a>
                    )}
                    <div className="clearfix"></div>
                    <ul className={clarityUser ? "sub-list" : ""}>
                      {accounts.map(({ id: gid, name }) => (
                        <li className="link" key={gid}>
                          {clarityUser && (
                            <div className="btn-group">
                              <div
                                className="btn btn-xs btn-default dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <span className="caret"></span>
                              </div>
                              <ul className="dropdown-menu">
                                <li>
                                  <a href={`/groups/${id}/accounts/${gid}`}>
                                    <i className="fa fa-show"></i> Show
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href={`/groups/${id}/accounts/${gid}/edit`}
                                  >
                                    <i className="fa fa-edit"></i> Edit
                                  </a>
                                </li>
                                <li>
                                  <a href={`/accounts/${gid}/revisions`}>
                                    <i className="fa fa-revisions"></i>{" "}
                                    Revisions
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                          ({gid}) {name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {clarityUser && (
                  <div className="row">
                    <div className="col-sm-12 status">{status_notes}</div>
                  </div>
                )}
              </div>
              <div className="footer">
                <div className="crud index">
                  {clarityUser && (
                    <a
                      id={`show-tab-${id}`}
                      className="show-tab btn btn-default"
                      href={`/groups/${id}`}
                    >
                      <i
                        className="fa fa-show"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Show
                    </a>
                  )}
                  {isAdmin && (
                    <a
                      id={`edit-tab-${id}`}
                      className="edit-tab btn btn-default"
                      href={`/groups/${id}/edit`}
                    >
                      <i
                        className="fa fa-edit"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Edit
                    </a>
                  )}
                  {clarityUser && (
                    <a
                      id={`revisions-tab-${id}`}
                      className="revisions-tab btn btn-default"
                      href={`/groups/${id}/revisions`}
                    >
                      <i
                        className="fa fa-revisions"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Revisions
                    </a>
                  )}
                  {(isAdmin || statusSetter) && (
                      <a
                        data-no-turbolink="true"
                        id={`users_download-tab-${id}`}
                        className="users_download-tab btn btn-default"
                        href={`/groups/${id}/users_download`}
                      >
                        <i
                          className="fa fa-download non-blocked users"
                          style={{ marginRight: "5px" }}
                        ></i>
                        Download Non-blocked Users
                      </a>
                    )}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

Groups.propTypes = {};

export default Groups;
