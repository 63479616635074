import React from "react";

const MagicSSNs = (props) => {
  const { productModelMocks } = props;

  return (
    <div>
      {productModelMocks.map(
        ({
          id,
          social_security_number,
          mock_attributes,
          action,
          identity_id,
          all_products_for_ssn,
          inquiry_attributes,
          control_file_attributes,
          product_name,
        }) => (
          <div className="row" key={id}>
            <div className="row-block col-lg-12">
              <div className="header">
                {social_security_number} - {product_name}
              </div>
              <div className="body">
                <div className="row">
                  <div className="col-md-3">
                    <dl>
                      <dt className="heading"></dt>
                      <dt>SSN/Identity</dt>
                      <dd>
                        {social_security_number &&
                          social_security_number.replace(
                            /(\d{3})(\d{2})(\d{4})/,
                            "$1-$2-$3"
                          )}
                        <br />
                        {identity_id}
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-3">
                    <dl>
                      <dt>Action</dt>
                      <dd>{action}</dd>
                    </dl>
                  </div>
                  <div className="col-md-3">
                    <dl>
                      <dt>All Products for SSN</dt>
                      {all_products_for_ssn.map((product) => (
                        <dd key={product}>{product}</dd>
                      ))}
                    </dl>
                  </div>
                  <div className="col-md-3">
                    <dl>
                      <ul className="icons no-margin-bottom">
                        <li>
                          <i
                            className={`fa ${
                              mock_attributes
                                ? "fa-check-square-o"
                                : "fa-square-o"
                            }`}
                          ></i>
                          Response Attributes Mocked?
                        </li>
                      </ul>
                      <ul className="icons no-margin-bottom">
                        <li>
                          <i
                            className={`fa ${
                              inquiry_attributes
                                ? "fa-check-square-o"
                                : "fa-square-o"
                            }`}
                          ></i>
                          <span
                            className="translation_missing"
                            title="translation missing: en.inquiry_attributes?"
                          >
                            Inquiry Attributes?
                          </span>
                        </li>
                      </ul>
                      <ul className="icons no-margin-bottom">
                        <li>
                          <i
                            className={`fa ${
                              control_file_attributes
                                ? "fa-check-square-o"
                                : "fa-square-o"
                            }`}
                          ></i>
                          <span
                            className="translation_missing"
                            title="translation missing: en.control_file_attributes?"
                          >
                            Control File Attributes?
                          </span>
                        </li>
                      </ul>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="footer">
                <div className="crud index">
                  <a
                    id={`show-tab-${id}`}
                    className="show-tab btn btn-default"
                    href={`/magic_ssns/${id}`}
                  >
                    <i className="fa fa-show" style={{marginRight: "5px"}}></i>
                    Show
                  </a>
                  <a
                    id={`edit-tab-${id}`}
                    className="edit-tab btn btn-default"
                    href={`/magic_ssns/${id}/edit`}
                  >
                    <i className="fa fa-edit" style={{marginRight: "5px"}}></i>
                    Edit
                  </a>
                  <a
                    data-confirm="Are you sure you want to delete this record?"
                    id={`destroy-tab-${id}`}
                    className="destroy-tab btn btn-default"
                    rel="nofollow"
                    data-method="delete"
                    href={`/magic_ssns/${id}`}
                  >
                    <i className="fa fa-destroy" style={{marginRight: "5px"}}></i>
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

MagicSSNs.propTypes = {};

export default MagicSSNs;
