import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import { PatternFormat } from "react-number-format";
import * as yup from "yup";
import { ssnRegExp } from "../constants/CommonRegex";

const formikValuesToAPIPayloadMapper = (
  authenticityToken,
  {
    socialSecurityNumber,
    productName,
    mockAttributes,
    inquiryAttributes,
    controlFileAttributes,
  },
  actionName
) => {
  let payload = {
    _method: "patch",
    authenticity_token: authenticityToken,
    "product_model_mock[social_security_number]": socialSecurityNumber,
    "product_model_mock[product_name]": productName && productName.value,
    "product_model_mock[mock_attributes]": mockAttributes,
    "product_model_mock[inquiry_attributes]": inquiryAttributes,
    "product_model_mock[control_file_attributes]": controlFileAttributes,
    commit: actionName === "new" ? "Create" : "Update",
  };

  actionName === "new" && delete payload._method;

  let formData = new FormData();

  Object.entries(payload).forEach(([k, v]) => formData.append(k, v));

  return formData;
};

const validationSchema = yup.object({
  socialSecurityNumber: yup
    .string()
    .notRequired()
    .matches(ssnRegExp, "Social security number can only have 9 digits"),
  productName: yup
    .object()
    .shape({ value: yup.string().required() })
    .default(undefined)
    .required("Please select a product."),
  mockAttributes: yup.string().test("json", "Invalid JSON format", (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  }),
  inquiryAttributes: yup
    .string()
    .notRequired()
    .test("json", "Invalid JSON format", (value) => {
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    }),
  controlFileAttributes: yup
    .string()
    .notRequired()
    .test("json", "Invalid JSON format", (value) => {
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    }),
});

const initialValues = {
  socialSecurityNumber: "",
  productName: "",
  mockAttributes: "{}",
  inquiryAttributes: "{}",
  controlFileAttributes: "{}",
};

const EditMagicSSNs = (props) => {
  const { productModelMock, actionName, productNames, authenticityToken } =
    props;

  const {
    id,
    all_products_for_ssn,
    mocked_inquiry_attributes,
    mocked_control_file_attributes,
    social_security_number,
    product_name,
    mock_attributes,
    inquiry_attributes,
    control_file_attributes,
  } = productModelMock;

  const [submissionError, setSubmissionError] = useState(null);
  const [productNamesOptions, setProductNamesOptions] = useState([]);

  useEffect(() => {
    if (productNames.length) {
      let tmpOpts = productNames.map((name) => ({
        value: name,
        label: name,
      }));

      setProductNamesOptions(tmpOpts);

      let tmpProdName = tmpOpts.find(
        (product) => product.value === product_name
      );

      tmpProdName && formik.setFieldValue("productName", tmpProdName);
    }
  }, [productNames, product_name]);

  initialValues.socialSecurityNumber =
    actionName === "new" ? "" : social_security_number;
  initialValues.mockAttributes = mock_attributes
    ? JSON.stringify(mock_attributes, null, 4)
    : "{}";
  initialValues.inquiryAttributes = inquiry_attributes
    ? JSON.stringify(inquiry_attributes, null, 4)
    : "{}";
  initialValues.controlFileAttributes = control_file_attributes
    ? JSON.stringify(control_file_attributes, null, 4)
    : "{}";

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = formikValuesToAPIPayloadMapper(
        authenticityToken,
        values,
        actionName
      );

      fetch(actionName === "edit" ? `/magic_ssns/${id}` : `/magic_ssns`, {
        method: "POST",
        body: payload,
      })
        .then((res) => res.json())
        .then((response) => {
          const { error, status } = response;
          switch (status) {
            case 200:
              window.location.assign(`${window.location.origin}/magic_ssns`);
              break;
            case 403:
              setSubmissionError(error);
              break;
            case 400:
              setSubmissionError(error);
              break;
          }
        });
    },
  });

  return (
    <>
      {submissionError && (
        <div className="flasher">
          <div className="alert alert-error form-errors">
            <button
              className="close"
              data-dismiss="alert"
              type="button"
              onClick={() => setSubmissionError(null)}
            >
              ×
            </button>
            <ul className="list-unstyled margin-bottom-0">
              <li>{submissionError}</li>
            </ul>
          </div>
        </div>
      )}
      <form
        className="simple_form form-horizontal"
        id={`${actionName}_product_model_mock`}
        autoComplete="false"
        noValidate="novalidate"
        onSubmit={formik.handleSubmit}
        onBlur={formik.handleBlur}
      >
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
          autoComplete="off"
        />
        <div className="col-sm-6 col-sm-offset-3">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Magic SSN Details</h3>
            </div>
            <div className="panel-body">
              <div
                className={`form-group string optional product_model_mock_social_security_number has-${
                  formik.touched.socialSecurityNumber
                    ? formik.errors.socialSecurityNumber
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="col-sm-3 control-label string optional"
                  htmlFor="product_model_mock_social_security_number"
                >
                  SSN
                </label>
                <div className="col-sm-9">
                  <PatternFormat
                    className="form-control string"
                    spellCheck="false"
                    format="###-##-####"
                    valueIsNumericString
                    mask="_"
                    id="product_model_mock_social_security_number"
                    name="socialSecurityNumber"
                    value={formik.values.socialSecurityNumber}
                    onValueChange={(values) => {
                      const { _formattedValue, value } = values;
                      formik.setFieldValue("socialSecurityNumber", value);
                    }}
                  />
                  {formik.touched.socialSecurityNumber &&
                    formik.errors.socialSecurityNumber && (
                      <small className="help-block error-block">
                        {formik.errors.socialSecurityNumber}
                      </small>
                    )}
                </div>
              </div>
              <div
                className={`form-group select required product_model_mock_product_name has-${
                  formik.touched.productName
                    ? formik.errors.productName
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="col-sm-3 control-label select required"
                  htmlFor="product_model_mock_product_name"
                >
                  <abbr title="required">*</abbr> Product name
                </label>
                <div className="col-sm-9">
                  <Select
                    options={productNamesOptions}
                    required
                    aria-required="true"
                    openOnFocus
                    tabSelectsValue={false}
                    name="productName"
                    id="product_model_mock_product_name"
                    className="required selectize selectized"
                    spellCheck="false"
                    placeholder="Select a Product"
                    value={formik.values.productName}
                    onChange={(payload) => {
                      formik.setFieldValue("productName", payload);
                    }}
                  />
                  {formik.touched.productName && formik.errors.productName && (
                    <small className="help-block error-block">
                      {formik.errors.productName}
                    </small>
                  )}
                </div>
              </div>
              <div
                className={`form-group text optional product_model_mock_mock_attributes has-${
                  formik.touched.mockAttributes
                    ? formik.errors.mockAttributes
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="col-sm-3 control-label text optional"
                  htmlFor="product_model_mock_mock_attributes"
                >
                  Response Attributes
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control text optional"
                    spellCheck="false"
                    rows="10"
                    name="mockAttributes"
                    id="product_model_mock_mock_attributes"
                    value={formik.values.mockAttributes}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.touched.mockAttributes &&
                    formik.errors.mockAttributes && (
                      <small className="help-block error-block">
                        {formik.errors.mockAttributes}
                      </small>
                    )}
                  <p className="help-block">
                    The hash of attributes to mock for this product's response
                    complete with surrounding brackets {}.
                  </p>
                </div>
              </div>
              <div
                className={`form-group text optional product_model_mock_inquiry_attributes has-${
                  formik.touched.inquiryAttributes
                    ? formik.errors.inquiryAttributes
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="col-sm-3 control-label text optional"
                  htmlFor="product_model_mock_inquiry_attributes"
                >
                  Inquiry attributes
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control text optional"
                    spellCheck="false"
                    rows="10"
                    name="inquiryAttributes"
                    id="product_model_mock_inquiry_attributes"
                    value={formik.values.inquiryAttributes}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.touched.inquiryAttributes &&
                    formik.errors.inquiryAttributes && (
                      <small className="help-block error-block">
                        {formik.errors.inquiryAttributes}
                      </small>
                    )}
                  <p className="help-block">
                    The hash of attributes to mock for the incoming inquiry
                    complete with surrounding brackets {}.
                  </p>
                </div>
              </div>
              <div
                className={`form-group text optional product_model_mock_control_file_attributes has-${
                  formik.touched.controlFileAttributes
                    ? formik.errors.controlFileAttributes
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <label
                  className="col-sm-3 control-label text optional"
                  htmlFor="product_model_mock_control_file_attributes"
                >
                  Control file attributes
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control text optional"
                    spellCheck="false"
                    rows="10"
                    name="controlFileAttributes"
                    id="product_model_mock_control_file_attributes"
                    value={formik.values.controlFileAttributes}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.touched.controlFileAttributes &&
                    formik.errors.controlFileAttributes && (
                      <small className="help-block error-block">
                        {formik.errors.controlFileAttributes}
                      </small>
                    )}
                  <p className="help-block">
                    The hash of attributes to mock for the control file complete
                    with surrounding brackets {}. Note that products set here
                    are ignored. Products are determined by the product names.
                  </p>
                </div>
              </div>
            </div>
            <div className="panel-footer text-align-right">
              <div className="form-actions">
                <input
                  type="submit"
                  name="commit"
                  value={actionName == "new" ? "Create" : "Update"}
                  className="btn btn-primary"
                  data-disable-with="Update"
                  style={{ marginRight: "5px" }}
                />
                <a className="btn btn-default" href="/magic_ssns">
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
        {actionName === "edit" && (
          <div className="col-sm-6 col-sm-offset-3">
            <div className="page-header">
              <h1>All Values for this SSN</h1>
              <div className="clearfix"></div>
            </div>
            <dl>
              <dt>All Products for SSN</dt>
              {all_products_for_ssn.map((product) => (
                <dd key={product}>{product}</dd>
              ))}
            </dl>
            <div className="panel panel-default bg-fa">
              <div className="panel-heading">
                <h3 className="panel-title">Mocked Inquiry Attributes</h3>
              </div>
              <div className="panel-body">
                <table
                  className="table table-striped"
                  style={{ marginBottom: "30px" }}
                >
                  <thead>
                    <tr>
                      <th>Attribute</th>
                      <th className="right-align">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mocked_control_file_attributes &&
                      Object.entries(mocked_inquiry_attributes).map(
                        ([k, v]) => (
                          <tr key={k}>
                            <td>{k}</td>
                            <td className="right-align">{v}</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="panel panel-default bg-fa">
              <div className="panel-heading">
                <h3 className="panel-title">Mocked Control File Attributes</h3>
              </div>
              <div className="panel-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Attribute</th>
                      <th className="right-align">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mocked_control_file_attributes &&
                      Object.entries(mocked_control_file_attributes).map(
                        ([k, v]) => (
                          <tr key={k}>
                            <td>{k}</td>
                            <td className="right-align">{v}</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

EditMagicSSNs.propTypes = {};

export default EditMagicSSNs;
