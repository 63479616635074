import React from "react";

const ShowMagicSSNs = (props) => {
  const { productModelMock } = props;
  const {
    all_products_for_ssn,
    mock_attributes,
    inquiry_attributes,
    mocked_inquiry_attributes,
    mocked_control_file_attributes,
    control_file_attributes,
  } = productModelMock;

  return (
    <div>
      <div className="row">
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Mocked Response Attributes</h3>
            </div>
            <div className="panel-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Attribute</th>
                    <th className="right-align">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {mock_attributes && Object
                    .entries(mock_attributes)
                    .map(([k, v]) => (
                      <>
                        <tr>
                          <td>{k}</td>
                          <td className="right-align">{v}</td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Mocked Inquiry Attributes</h3>
            </div>
            <div className="panel-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Attribute</th>
                    <th className="right-align">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {inquiry_attributes &&
                    Object.entries(inquiry_attributes).map(([k, v]) => (
                      <>
                        <tr>
                          <td>{k}</td>
                          <td className="right-align">{v}</td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Mocked Control File Attributes</h3>
            </div>
            <div className="panel-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Attribute</th>
                    <th className="right-align">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {control_file_attributes &&
                    Object.entries(control_file_attributes).map(([k, v]) => (
                      <>
                        <tr>
                          <td>{k}</td>
                          <td className="right-align">{v}</td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">All Values for this SSN</h3>
            </div>
            <div className="panel-body">
              <dl>
                <dt>All Products for SSN</dt>
                {all_products_for_ssn.map((product) => (
                  <dd>{product}</dd>
                ))}
              </dl>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Mocked Inquiry Attributes</h3>
            </div>
            <div className="panel-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Attribute</th>
                    <th className="right-align">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {mocked_inquiry_attributes &&
                    Object.entries(mocked_inquiry_attributes).map(([k, v]) => (
                      <>
                        <tr>
                          <td>{k}</td>
                          <td className="right-align">{v}</td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Mocked Control File Attributes</h3>
            </div>
            <div className="panel-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Attribute</th>
                    <th className="right-align">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {mocked_control_file_attributes &&
                    Object.entries(mocked_control_file_attributes).map(
                      ([k, v]) => (
                        <>
                          <tr>
                            <td>{k}</td>
                            <td className="right-align">{v}</td>
                          </tr>
                        </>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ShowMagicSSNs.propTypes = {};

export default ShowMagicSSNs;
